<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>FACTURES</h3>
        <card>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-8 col-lg-12 col-12">
                <div class="row">
                  <base-input label="Année" class="col-md-3">
                    <el-select class="select-primary"
                      placeholder="CHOISISSEZ"
                      :disabled="$apollo.queries.getFactureAn.loading || $apollo.queries.getFactureMarket.loading"
                      v-model="YearsSelects.Year">
                      <el-option 
                        v-for="option in YearsSelects.YearList"
                        class="select-primary"
                        :value="option.value"
                        :label="option.value"
                        :key="option.value">
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input label="mois" class="col-md-3">
                    <el-select class="select-primary" clearable filterable
                      placeholder="CHOISISSEZ"
                      v-model="MounthSelects.Mounth">
                      <el-option 
                        v-for="option in MounthSelects.MounthList"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.value">
                      </el-option>
                    </el-select>
                  </base-input>
                  <base-input label="Platforme" class="col-md-3">
                    <el-select class="select-primary" clearable filterable
                      placeholder="CHOISISSEZ"
                      v-model="PlatformSelects.Platform">
                      <el-option 
                        v-for="option in PlatformSelects.PlatformList"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.value">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </card>
        <card>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-1 col-6">
                <base-input>
                  <el-select
                    class="select-primary mb-3 pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-11 col-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
            </div>
            <!-- <center v-if="">
              <base-button 
                loading 
                round 
                class="btn btn-round btn-just-icon btn-primary mb-2"
              >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
            </center> -->
            <el-table :data="queriedData" v-loading="$apollo.queries.getFactureAn.loading || $apollo.queries.getFactureMarket.loading" show-summary :summary-method="getSummaries">
              <el-table-column
                prop="ref_perf"
                label="Ref"
                class="table-striped"
                min-width="120"
              >
                <template scope="sope">
                  {{ sope.row.ref_perf || sope.row.ref_coreg }}
                </template>
              </el-table-column>
              <el-table-column
                prop="periode_perf"
                label="Période"
                class="table-striped"
                min-width="120"
                sortable
                :sort-method="Custom_SortPeriode"
              >
                <template scope="sope">
                  {{ sope.row.periode_perf || sope.row.periode_coreg }}
                </template>
              </el-table-column>
              <el-table-column
                prop="statut_coreg"
                label="Statut"
                class="table-striped"
                min-width="120"
              >
                <template scope="sope">
                  {{ getStatus(sope.row.statut_perf) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="montant_ht_perf"
                label="Montant (HT)"
                class="table-striped"
                min-width="130"
                sortable
                :sort-method="CustomSort_Sortmontantht"
              >
                <template scope="sope">
                  <template v-if="sope.row.flag == 'perf'">
                    {{ formatThounsends(sope.row.montant_ht_perf,true,1) + " €" }}
                  </template>
                  <template v-else-if="sope.row.flag == 'coreg'">
                    {{ formatThounsends(sope.row.montant_ht_coreg,true,1) + " €" }}
                  </template>
                  <template v-else-if="sope.row.flag == 'market'">
                    {{ formatThounsends(sope.row.montant_ht_perf,true,1) + " €" }}
                  </template>
                  <template v-else-if="sope.row.flag == 'multi'">
                    {{ formatThounsends((sope.row.montant_ht_coreg + sope.row.montant_ht_perf),true,1) + " €" }}
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="montant_ttc_perf"
                label="Montant (TTC)"
                class="table-striped"
                min-width="135"
                sortable
                :sort-method="CustomSort_Sortmontant_ttc"
              >
                <template scope="sope">
                  <template v-if="sope.row.flag == 'perf'">
                    {{ formatThounsends(sope.row.montant_ttc_perf,true,1) + " €" }}
                  </template>
                  <template v-else-if="sope.row.flag == 'coreg'">
                    {{ formatThounsends(sope.row.montant_ttc_coreg,true,1) + " €" }}
                  </template>
                  <template v-else-if="sope.row.flag == 'market'">
                    {{ formatThounsends(sope.row.montant_ttc_perf,true,1) + " €" }}
                  </template>
                  <template v-else-if="sope.row.flag == 'multi'">
                    {{ formatThounsends((sope.row.montant_ttc_coreg + sope.row.montant_ttc_perf),true,1) + " €" }}
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="Actions" minWidth="130">
                <div slot-scope="props">
                  <el-tooltip v-if="props.row.flag == 'market'" content="	Télécharger facture" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="DownloadFactureMarket(props.row.periode_perf.replace('/','-'))"
                      class="edit btn-link"
                      type="success"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons el-icon-download icon-large"></i>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip v-if="props.row.flag != 'market'" content="Téléchargement" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="Download(props.row)"
                      class="edit btn-link"
                      type="success"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons el-icon-download icon-large"></i>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip v-if="props.row.flag != 'market'" content="Règlement en ligne" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="OnlinePay(props.row)"
                      :disabled="props.row.statut_perf === 2 || props.row.statut_perf === 8 || props.row.statut_perf === 9 || props.row.statut_perf === 11 ||
                                  props.row.statut_coreg === 2 || props.row.statut_coreg === 8 || props.row.statut_coreg === 9 || props.row.statut_coreg === 11          
                      "
                      class="edit btn-link"
                      type="success"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons el-icon-document icon-large"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>
              <el-table-column
                label="Platforme"
                :min-width="120"
                class="table-striped"
              >
                <template scope="scope">
                  <el-tooltip v-if="scope.row.flag == 'multi' || scope.row.flag == 'perf'" content="WEEDO PERF" effect="light" :open-delay="300" placement="top">
                    <span class="btn btn-link btn-default btn-icon btn-sm like">
                      <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.flag == 'multi' || scope.row.flag == 'coreg'" content="WEEDO COREG" effect="light" :open-delay="300" placement="top">
                    <span class="btn btn-link btn-default btn-icon btn-sm like">
                      <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.flag == 'market'" content="WEEDO MARKET" effect="light" :open-delay="300" placement="top">
                    <span class="btn btn-link btn-default btn-icon btn-sm like">
                      <svg version="1.1" id="Calque_1" fill="#588bbd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class>
                <p
                  class="card-category"
                >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
    <modal
      :show.sync="ShowPDF"
      body-classes="p-0"
      class="modal-listbase"
      :show-close="true"
      >
        <base-button style="float: right;" @click.native="ShowPDF = false" class="like btn-link" type="danger" size="sm" icon>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>
        <object :data="PDFURL" type="application/pdf" style="width: 100%;height: 900px;">
          <iframe :src="PDFURL"></iframe>
        </object>
    </modal>
  </div>
</template>
  
  <script>
  import { Select, Option, DatePicker } from "element-ui";
  import { Table, TableColumn } from "element-ui";
  import { BasePagination } from "src/components";
  import gql from "graphql-tag";
  import {Modal} from 'src/components'

  let today = new Date()

  export default {
    watch: {
      'YearsSelects.Year'() {
        this.listFactures = []
      }
    },
    apollo: {
      getFactureAn: {
        client: 'AUTH',
        query: require("src/graphql/Factures_annonceur.gql"),
        fetchPolicy: 'network-only',
        result({ data, loader, networkStatus }) {
          this.listFactures = this.listFactures.concat(data.getFactureAn)
        },
        variables () {
          return {
            annee: this.YearsSelects.Year,
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
            // id_utilisateur: 383
          }
        }
      },
      getFactureMarket: {
        client: 'MARKET',
        query: gql `
          query ($id_utilisateur: ID, $annee: String) {
            mesFactures(id_utilisateur: $id_utilisateur, annee: $annee) {
              mois
              annee
              societe
              pk_annonceur
              total_mois
              remise
              remboursement_devalidations
              total_ttc
              montant_tva
            }
          }
        `,
        fetchPolicy: 'network-only',
        result({ data, loader, networkStatus }) {
          data.mesFactures.forEach(facture => {
            this.listFactures.push(
              {
                ref_perf: "-",
                ref_coreg: null,
                periode_perf: facture.mois+"/"+facture.annee,
                periode_coreg: null,
                statut_perf: 0,
                statut_coreg: null,
                montant_ht_perf: facture.total_mois - facture.remboursement_devalidations,
                montant_ht_coreg: null,
                montant_ttc_perf: facture.total_ttc,
                montant_ttc_coreg: null,
                flag: "market",
                client_perf: null,
                client_coreg: null
              }
            )
          });
        },
        variables () {
          return {
            annee: this.YearsSelects.Year,
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          }
        },
        update: data => data.mesFactures,
        skip() {
          return (this.id_utilisateur == 2519 || this.id_utilisateur == 2134)
        }
      },
    },
    components: {
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      Modal
    },
    data() {
      return {
        id_utilisateur : parseInt(JSON.parse(localStorage.getItem('utilisateur')).id),
        montantTotal_HT: 0,
        montantTotal_TTC: 0,
        PDFURL: '',
        ShowPDF: false,
        total: 0,
        listFactures: [],
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [ 10, 25, 50],
          total: 0
        },
        YearsSelects: {
          Year: today.getFullYear(),
          YearList: [
            {value: today.getFullYear()},
            {value: today.getFullYear() - 1}
          ]
        },
        PlatformSelects: {
          Platform: "",
          PlatformList: [
            { label: 'WEEDO PERF', value: 'perf'},
            { label: 'WEEDO MARKET', value: 'market'},
            { label: 'WEEDO COREG', value: 'coreg'}
          ]
        },
        MounthSelects: {
          Mounth: "",
          MounthList: [
            { label: 'JANVIER', value: '1/'},
            { label: 'FÉVRIER', value: '2/'},
            { label: 'MARS', value: '3/'},
            { label: 'AVRIL', value: '4/'},
            { label: 'MAI', value: '5/'},
            { label: 'JUIN', value: '6/'},
            { label: 'JUILLET', value: '7/'},
            { label: 'AOÛT', value: '8/'},
            { label: 'SEPTEMBRE', value: '9/'},
            { label: 'OCTOBRE', value: '10/'},
            { label: 'NOVEMBRE', value: '11/'},
            { label: 'DÉCEMBRE', value: '12/'}
          ]
        },
        ListEtat: [
          { value: 0, label: "-"},
          { value: 1, label: "ECHOUÉ"},
          { value: 2, label: "PAYEE"},
          { value: 3, label: "RELANCE 1"},
          { value: 4, label: "RELANCE 2"},
          { value: 5, label: "RELANCE 3"},
          { value: 6, label: "LRAR"},
          { value: 7, label: "-"},
          { value: 8, label: "PAYEE AVEC PAYPAL"},
          { value: 9, label: "PAYEE AVEC SOGENACTIF"},
          { value: 11, label: "PRÉPAIEMENT"},
          { value: null, label: "-"},
        ]
      };
    },
    computed: {
      queriedData() {
        let result = [];

        result = this.listFactures.filter((facture) => {
          if(facture.periode_perf == null)
            facture.periode_perf = ""
          if(facture.periode_coreg == null)
            facture.periode_coreg = ""
          if (this.PlatformSelects.Platform === 'perf' || this.PlatformSelects.Platform === 'coreg') {
            return  facture.flag.match(this.PlatformSelects.Platform) || facture.flag.match('multi') &&
                    (this.MounthSelects.Mounth === '1/' || this.MounthSelects.Mounth === '2/') 
                    ? (facture.periode_perf.startsWith(this.MounthSelects.Mounth) || facture.periode_coreg.startsWith(this.MounthSelects.Mounth))
                    : (facture.periode_perf.match(this.MounthSelects.Mounth) || facture.periode_coreg.match(this.MounthSelects.Mounth))
                    
          }
          else {
            return  facture.flag.match(this.PlatformSelects.Platform) &&
            (this.MounthSelects.Mounth === '1/' || this.MounthSelects.Mounth === '2/') 
            ? (facture.periode_perf.startsWith(this.MounthSelects.Mounth) || facture.periode_coreg.startsWith(this.MounthSelects.Mounth))
            : (facture.periode_perf.match(this.MounthSelects.Mounth) || facture.periode_coreg.match(this.MounthSelects.Mounth))
          }
        })

        this.total = result.length
        let mt_total_ht = 0
        let mt_total_ttc = 0
        result.slice(this.from, this.total).forEach(element => {
          mt_total_ht += (element.montant_ht_perf) ? parseFloat(element.montant_ht_perf) : 0
          mt_total_ht += (element.montant_ht_coreg) ? parseFloat(element.montant_ht_coreg) : 0
          mt_total_ttc += (element.montant_ttc_perf) ? parseFloat(element.montant_ttc_perf) : 0
          mt_total_ttc += (element.montant_ttc_coreg) ? parseFloat(element.montant_ttc_coreg) : 0
        });
        this.montantTotal_HT = mt_total_ht
        this.montantTotal_TTC = mt_total_ttc

        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    methods: {
      Custom_SortPeriode(a, b) {
        if (a.periode_perf && b.periode_perf)
          return a.periode_perf.localeCompare(b.periode_perf);
        else if (a.periode_coreg && b.periode_coreg)
          return a.periode_coreg.localeCompare(b.periode_coreg);
        else if (a.periode_perf && b.periode_coreg)
          return a.periode_perf.localeCompare(b.periode_coreg);
        else if (a.periode_coreg && b.periode_perf)
          return a.periode_coreg.localeCompare(b.periode_perf);
      },
      CustomSort_Sortmontantht(a, b) {
        if (a.montant_ht_perf!=null && b.montant_ht_perf!=null)
          return a.montant_ht_perf - b.montant_ht_perf
        else if (a.montant_ht_coreg!=null && b.montant_ht_coreg!=null)
          return a.montant_ht_coreg - b.montant_ht_coreg
        else if (a.montant_ht_perf!=null && b.montant_ht_coreg!=null)
          return a.montant_ht_perf - b.montant_ht_coreg
        else if (a.montant_ht_coreg!=null && b.montant_ht_perf!=null)
          return a.montant_ht_coreg - b.montant_ht_perf
      },
      CustomSort_Sortmontant_ttc(a, b) {
        if (a.montant_ttc_perf!=null && b.montant_ttc_perf!=null)
          return a.montant_ttc_perf - b.montant_ttc_perf
        else if (a.montant_ttc_coreg!=null && b.montant_ttc_coreg!=null)
          return a.montant_ttc_coreg - b.montant_ttc_coreg
        else if (a.montant_ttc_perf!=null && b.montant_ttc_coreg!=null)
          return a.montant_ttc_perf - b.montant_ttc_coreg
        else if (a.montant_ttc_coreg!=null && b.montant_ttc_perf!=null)
          return a.montant_ttc_coreg - b.montant_ttc_perf
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      Download(row) {
        let periode = row.periode_perf || row.periode_coreg
        const url_api = "https://api-auth-plf.wee-do-it.net/api/v1/pdf_facture"
        let accessToken = localStorage.getItem("token_jwt");
        let formData = new FormData()
        formData.append('mois', ((periode.split('/')[0]+"").length==1) ? "0"+periode.split('/')[0] : periode.split('/')[0])
        formData.append('annee', periode.split('/')[1])
        formData.append('client_perf', row.client_perf)
        formData.append('client_coreg', row.client_coreg)
        this.axios
          .post(url_api,formData , {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
            responseType: "blob"
          }).then(function(response) {
            let blob = new Blob([response.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            this.ShowPDF = true
            this.PDFURL = window.URL.createObjectURL(blob)
          }.bind(this))
          .catch(function (error) {
            console.log(error.message);
          })
      },
      getStatus(id) {
        return this.ListEtat.find(x => x.value === id).label
      },
      DownloadFactureMarket(periode) {
        const url_api = "https://apileadmarket.wee-do-it.net/api/generate-facture-pdf";
          let accessToken = localStorage.getItem("token_jwt");
          let formData = new FormData()
          formData.append('periode', periode)
          formData.append('id_utilisateur', JSON.parse(localStorage.getItem('utilisateur')).id)
          this.axios.post(url_api,formData , {
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded',
              authorization: `Bearer ${accessToken}` 
            },
            responseType: "blob"
          }).then(function(response) {

            const filename = "facture-" + periode +".pdf";
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", filename);
            document.body.appendChild(fileLink);
            fileLink.click();

          }.bind(this))
          .catch(function (error) {
            console.log(error.message);
          })
      },
      OnlinePay(row) {
        localStorage.setItem("facture", JSON.stringify(row))
        this.$router.push({ name: "prepaiement"})
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "TOTAL";
            return;
          } else if (index === 3) {
            sums[index] = this.formatThounsends(this.montantTotal_HT, true, 2) + ' €';
            return;
          } else if (index === 4) {
            sums[index] = this.formatThounsends(this.montantTotal_TTC, true, 2) + ' €';
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
    }
  };
  </script>