<template>
  <div class="content">
    <div class="row">
        <div class="col-lg-12">
          <div class="col-sm-6 text-left">
            <h2 class="card-title"> MES PRÉPAIEMENTS</h2>
          </div>
        </div>

        <div class="col-lg-12">
            <div class="col-lg-6 alert alert-info">
                Nous vous invitons à créditer /pré-payer votre compte. Le minimum est de 1000€.<br/>
                Pour toute question, nous sommes joignables au 04 72 61 26 31 ou par e-mail à contact@weedoit.fr
            </div>
        </div>

        <div class="col-lg-6">
            <card>
                <div class="col-sm-12">

                    <h4 class="info-text" style="color: #988fc3">CRÉDITER AVEC UNE CARTE BANCAIRE</h4>

                    <base-input label="LISTE DES DOCUMENTS">
                        <el-select class="select-primary"
                        placeholder="SÉLECTIONNER LES CRITÈRES"
                        v-model="id_oi"
                        @change="getMontantTotal">
                        <el-option
                            v-for="document in documents"
                            class="select-primary"
                            v-if="document.paid != 'YES'"
                            :value="document.id_oi"
                            :label="document.numero_oi.toUpperCase()"
                            :key="document.id_oi">
                        </el-option>
                        </el-select>
                    </base-input>

                    <base-input
                        label="Montant (obligatoire) - €"
                        name="email"
                        required
                        v-model="amount"
                        type="number"
                        disabled="true"
                    ></base-input>

                    <base-button class="pull-right" native-type="submit" type="primary" v-on:click="payment" :disabled="disableButton">VALIDER</base-button>

                </div>
            </card>
        </div>

        <div class="col-lg-6">
            <card>
                <div class="corps" v-if="state == 'paiement_begin'">
                    <div class="form-row">
                        <label class="col-md-12">Détails de votre prépaiement :</label>
                    </div>
                    <div class="form-row">
                        <label class="col-md-6">Montant à payer :</label>
                        <label class="col-md-6">{{ amount }} €</label>
                    </div>
                    <br>
                    <label>
                      <div v-html="message">
                      </div>
                    </label>
                    <br>
                    <br>
                    <br>
                </div>

                <div v-else-if="state == 'payment_completed'">
                    <h1>payment completed</h1>
                </div>
            </card>
        </div>

        <div class="col-lg-12">
          <card>
            <div class="card-body">
              <div>
                <div
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <div class="col-xl-1 col-6">
                    <base-input>
                      <el-select
                        class="select-primary mb-3 pagination-select"
                        v-model="pagination.perPage"
                        placeholder="Per page"
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in pagination.perPageOptions"
                          :key="item"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-xl-11 col-6">
                    <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }} </label>
                  </div>
                </div>
                <el-table :data="queriedData" show-summary :summary-method="getSummaries">
                  <el-table-column
                    prop="id"
                    label="Ref"
                    class="table-striped"
                    min-width="50"
                    sortable
                  >
                    <template scope="props">{{ props.row.id }}</template>
                  </el-table-column>

                  <el-table-column
                    prop="paymentDate"
                    label="Date"
                    class="table-striped"
                    min-width="110"
                    sortable
                  >
                    <template scope="props">{{ props.row.paymentDate }}</template>
                  </el-table-column>

                  <el-table-column
                    prop="montant"
                    label="Montant ( HT )"
                    class="table-striped"
                    min-width="120"
                    sortable
                  >
                    <template
                      scope="props"
                    >{{ formatThounsends(props.row.montant, true, 2) + ' €' }}</template>
                  </el-table-column>

                  <el-table-column
                    prop="source"
                    label="Source"
                    class="table-striped"
                    min-width="120"
                    sortable
                  >
                    <template
                      scope="props"
                    >{{ (!props.row.source) ? '-' : props.row.source.toUpperCase() }}</template>
                  </el-table-column>

                  <el-table-column
                    prop="etat"
                    label="Etat"
                    class="table-striped"
                    min-width="120"
                    sortable
                  >
                    <template
                      scope="props"
                    >{{ paymentEtat(props.row.etat).toUpperCase() }}</template>
                  </el-table-column>
                  <el-table-column label="Actions" minWidth="130">
                    <div slot-scope="props" v-if="props.row.paymentapi == 'sogenactif' &&  props.row.etat == 'acceptee'">
                      <el-tooltip
                        content="Télécharger le reçu"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                        >
                        <base-button
                            @click.native="handleDownload(props.row)"
                            class="btn-link"
                            type="danger"
                            size="sm"
                            icon
                        >
                            <i class="tim-icons el-icon-document"></i>
                        </base-button>
                        </el-tooltip>
                    </div>
                  </el-table-column>
                </el-table>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class>
                  <p
                    class="card-category"
                  >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                ></base-pagination>
              </div>
            </div>
          </card>
        </div>
    </div>
    <!-- <modal
      :show.sync="ShowPDF"
      body-classes="p-0"
      class="modal-listbase"
      :show-close="true"
      >
        <base-button style="float: right;" @click.native="ShowPDF = false" class="like btn-link" type="danger" size="sm" icon>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>
        <object :data="PDFURL" type="application/pdf" style="width: 100%;height: 900px;">
          <iframe :src="PDFURL"></iframe>
        </object>
    </modal> -->
  </div>
</template>

<script>
import gql from "graphql-tag";
import {
  ImageUpload,
  SimpleWizard,
  WizardTab,
  BaseAlert
} from "src/components";
import { BaseRadio } from "src/components/index";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { Popover } from "element-ui";
import { BaseSwitch } from "src/components/index";
import { Select, Option, DatePicker } from "element-ui";
import { Table, TableColumn } from "element-ui";
import { BasePagination } from "src/components";
import {Modal} from 'src/components'

export default {
    components: {
        BaseSwitch,
        VueGoogleAutocomplete,
        ImageUpload,
        BaseRadio,
        BaseAlert,
        [Select.name]: Select,
        [Option.name]: Option,
        [Popover.name]: Popover,
        BasePagination,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [DatePicker.name]: DatePicker,
        Modal
    },
      apollo: {
        listOi :
        {
            client: 'AUTH',
            query: gql` query ($id_annonceur: Int) {
                listDocuments (id_annonceur: $id_annonceur) {
                    id_oi
                    date_creation_oi
                    numero_oi
                    status
                    montant_total
                    signatureUrl
                    transactionId
                    paid
                },
                listPrepays(id_annonceur: $id_annonceur){
                    Prepaiements{
                      id
                      id_utilisateur
                      id_oi_globale
                      transaction_id
                      paid
                      etat
                      source
                      montant
                      paymentDate
                      paymentapi
                    }
                    montantTotal
                }
            }`,
            fetchPolicy: 'no-cache',
            variables () {
                return {
                    id_annonceur: JSON.parse(localStorage.getItem('utilisateur')).id
                }
            },
            result({ data, loader, networkStatus }) {
                this.documents = data.listDocuments
                this.tableData = data.listPrepays[0].Prepaiements || []
                this.montantTotal = data.listPrepays[0].montantTotal
            },
            update: data => data.listDocuments
        },
    },
    data() {
        return {
            documents: [],
            tableData: [],
            id_oi: null,
            utilisateur_id: JSON.parse(localStorage.getItem('utilisateur')).id,
            amount: null,
            action: 'sogenactif',
            state: false,
            message: '',
            total: 0,
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0
            },
            PDFURL: '',
            // ShowPDF: false,
            disableButton: true,
            montantTotal: null,
            facture: null
        }
    },
    methods: {
        payment(){

            let formData = new FormData()
            if (this.facture != null) {
              formData.append("action", "facture")
              formData.append("id", (this.facture.ref_perf) ? this.facture.ref_perf : "")
              formData.append("id_coreg", (this.facture.ref_coreg) ? this.facture.ref_coreg : "")
            }
            else {
              formData.append("action", "document")
              formData.append("id", this.id_oi)
            }
            formData.append("amount", this.amount)
            formData.append("utilisateur_id", this.utilisateur_id)

            const url_api = 'https://api-auth-plf.wee-do-it.net/api/v1/paymentgetway'
            let accessToken = localStorage.getItem("token_jwt")
            this.axios.post(url_api, formData,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': `Bearer ${accessToken}`
                }
            })
            .then(response => {
                if(response.data){
                    this.state = response.data.state
                    this.message = response.data.message
                    this.disableButton = true
                }
            })
            .catch(() => {
                console.log('error occured')
            })
        },
        getMontantTotal:function(){
          this.facture = null
            this.documents.forEach(document => {
                if(this.id_oi == document.id_oi){
                    this.amount = document.montant_total
                    this.id_oi = document.id_oi
                    this.disableButton = false
                }
            });
        },
        formatThounsends: function(value, fixe = false, number = 0) {
          if(value){
            if (fixe) {
              value = value.toFixed(number);
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
          return null
        },
        handleDownload(row){
          const url_api = "https://api-auth-plf.wee-do-it.net/api/v1/generatePdfPrepaiement";
          //const url_api = "http://localhost:9000/api/v1/generatePdfPrepaiement";
          let accessToken = localStorage.getItem("token_jwt");
          let formData = new FormData()
          formData.append('prepaiement_id', row.id)
          this.axios.post(url_api,formData , {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
              responseType: "blob"
            }).then(function(response) {
              let blob = new Blob([response.data], { type: 'application/pdf' })
              const filename = "reçu-" + row.transaction_id;
              let link = document.createElement('a')
              //this.ShowPDF = true
              this.PDFURL = window.URL.createObjectURL(blob)

              link.href = this.PDFURL;
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();

            }.bind(this))
            .catch(function (error) {
              console.log(error.message);
            })
        },
        paymentEtat(etat){
            if(etat == "refusee"){
                return "Paiement Refusé";
            } else if(etat == "acceptee") {
                return "Paiement Confirmé";
            }else return "-";
        },
        getSummaries(param) {
          const { columns, data } = param;
          const sums = [];
          columns.forEach((column, index) => {
            if (index === 0) {
              sums[index] = "TOTAL";
              return;
            } else if (index === 1) {
              sums[index] = "";
              return;
            } else if (index === 2) {
              sums[index] = this.formatThounsends(this.montantTotal, true, 2) + ' €';
              return;
            } else {
              sums[index] = "";
              return;
            }
          });
          return sums;
        }
    },
    computed: {
      queriedData() {
        let result = this.tableData;
        this.total = result.length;
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    created() {
      var response = typeof this.$route.query.response !== typeof undefined;
      if(response){
        if(this.$route.query.response == 'paiement_completed')
        {
          this.$notify({type: 'success', verticalAlign: 'top', horizontalAlign: 'right', message: 'Votre paiement à été traité avec succès.', duration: 10000 });
        }else if(this.$route.query.response == 'paiement_canceled')
        {
          this.$notify({type: 'warning', verticalAlign: 'top', horizontalAlign: 'right', message: 'Votre paiement a été refusé ou vous avez annulé la transaction!', duration: 10000 });
        }
        this.$router.replace('/mes-prepaiement')
      }

      if(JSON.parse(localStorage.getItem('facture')) != null) {
        this.facture = JSON.parse(localStorage.getItem('facture'))

        if (this.facture.flag == "multi")
          this.amount = (this.facture.montant_ttc_coreg + this.facture.montant_ttc_perf).toFixed(1)
        else if(this.facture.flag == "perf")
          this.amount = this.facture.montant_ttc_perf.toFixed(1)
        else
          this.amount = this.facture.montant_ttc_coreg.toFixed(1)

        this.disableButton = false
        localStorage.removeItem('facture')
      }
    },
    watch: {
      "documents": function (val) {
        if(localStorage.getItem('id_oi')) {
          this.id_oi = localStorage.getItem('id_oi')
          localStorage.removeItem('id_oi')
          this.getMontantTotal()
        }
      }
    }
}
</script>
